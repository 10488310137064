<template>
  <ul class="nav flex-column profile-menu mb-5" >
    <li class="nav-item mb-2">
      <strong>{{ $t('breadcrumb.association.association') }}</strong>
    </li>
    <li class="nav-item">
      <router-link :to="$router.resolve({name: urls_association.ASSOC_EDIT_PROFILE.name, query: {associationId: getClub?.data.No}})" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: $router.currentRoute.value.name === urls_association.ASSOC_EDIT_PROFILE.name}">
          {{ $t('breadcrumb.association.profile') }}
        </a>
      </router-link>
    </li>
    <li class="nav-item">
      <router-link :to="$router.resolve({name: urls_association.ASSOC_SHOW_GROUPS.name, query: {associationId: getClub?.data.No}})" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: $router.currentRoute.value.name === urls_association.ASSOC_SHOW_GROUPS.name}">
          {{ $t('breadcrumb.association.group') }}
        </a>
      </router-link>
    </li>
    <li class="nav-item" >
      <router-link :to="$router.resolve({name: urls_association.ASSOC_SHOW_PAYMENTS.name, query: {associationId: getClub?.data.No}})" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: $router.currentRoute.value.name === urls_association.ASSOC_SHOW_PAYMENTS.name}">
          {{ $t('breadcrumb.association.payment') }}
        </a>
      </router-link>
    </li>
    <li class="nav-item" >
      <router-link :to="$router.resolve({name: urls_association.ASSOC_SHOW_SYNC.name, query: {associationId: getClub?.data.No}})" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: $router.currentRoute.value.name === urls_association.ASSOC_SHOW_SYNC.name}">
          {{ $t('breadcrumb.association.sync') }}
        </a>
      </router-link>
    </li>

    <li class="nav-item mt-5 mb-2">
      <strong>{{ $t('breadcrumb.association.yourClubs') }}</strong>
    </li>
    <li class="nav-item" >
      <router-link :to="$router.resolve({name: urls_association.ASSOC_SHOW_CLUBS.name, query: {associationId: getClub?.data.No}})" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: $router.currentRoute.value.name === urls_association.ASSOC_SHOW_CLUBS.name}">
          {{ $t('breadcrumb.association.clubList') }}
        </a>
      </router-link>
    </li>
    <li class="nav-item" >
      <router-link :to="$router.resolve({name: urls_association.ASSOC_SHOW_ETAT.name, query: {associationId: getClub?.data.No}})" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: $router.currentRoute.value.name === urls_association.ASSOC_SHOW_ETAT.name}">
          {{ $t('breadcrumb.association.etat') }}
        </a>
      </router-link>
    </li>

    <li class="nav-item mt-5 mb-2">
      <strong>{{ $t('breadcrumb.association.yourMembers') }}</strong>
    </li>
    <li class="nav-item" >
      <router-link :to="$router.resolve({name: urls_association.ASSOC_SHOW_MEMBERS.name, query: {associationId: getClub?.data.No}})" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: $router.currentRoute.value.name === urls_association.ASSOC_SHOW_MEMBERS.name}">
          {{ $t('breadcrumb.association.memberList') }}
        </a>
      </router-link>
    </li>
    <li class="nav-item" >
      <router-link :to="$router.resolve({name: urls_association.ASSOC_SHOW_FUNCTIONS_AND_ACTIVITIES.name, query: {associationId: getClub?.data.No}})" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: $router.currentRoute.value.name === urls_association.ASSOC_SHOW_FUNCTIONS_AND_ACTIVITIES.name}">
          {{ $t('breadcrumb.association.functionsAndActivities') }}
        </a>
      </router-link>
    </li>
    <li class="nav-item" >
      <router-link :to="$router.resolve({name: urls_association.ASSOC_SHOW_HONORS.name, query: {associationId: getClub?.data.No}})" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: $router.currentRoute.value.name === urls_association.ASSOC_SHOW_HONORS.name}">
          {{ $t('breadcrumb.association.honors') }}
        </a>
      </router-link>
    </li>
    <li class="nav-item" >
      <router-link :to="$router.resolve({name: urls_association.ASSOC_SHOW_MEMBER_NUMBERS.name, query: {associationId: getClub?.data.No}})" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: $router.currentRoute.value.name === urls_association.ASSOC_SHOW_MEMBER_NUMBERS.name}">
          {{ $t('breadcrumb.association.memberNumbers') }}
        </a>
      </router-link>
    </li>
    <li class="nav-item mt-5 mb-2">
      <strong>{{ $t('breadcrumb.association.div') }}</strong>
    </li>
    <li class="nav-item" >
      <router-link :to="$router.resolve({name: urls_association.ASSOC_SHOW_LSPA.name, query: {associationId: getClub?.data.No}})" custom v-slot="{href}">
        <a :href="href" class="nav-link" :class="{active: $router.currentRoute.value.name === urls_association.ASSOC_SHOW_LSPA.name}">
          {{ $t('breadcrumb.association.lspaControl') }}
        </a>
      </router-link>
    </li>
  </ul>

</template>

<script>
import {mapState} from "pinia";
import {useLoginStore} from "@/store/modules/login/login.js";
import {useMemberStore} from "@/store/modules/member/member.js";
import {useClubStore} from "@/store/modules/club/club.js";
import urls_association from "@/router/urls_association";

export default {
  computed: {
    urls_association() {
      return urls_association
    },
    isSecurityActive() {
      const securityRoutes = [
        'change-two-factor-settings',
        'add-device',
      ];
      const currentRoute = this.$router.currentRoute.value.name;
      return securityRoutes.indexOf(currentRoute) > -1;
    },
    isEmailActive() {
      const emailRoutes = [
        'change-email',
        'confirm-email',
      ];
      const currentRoute = this.$router.currentRoute.value.name;
      return emailRoutes.indexOf(currentRoute) > -1;
    },
    isGroupsActive() {
      const groupRoutes = [
        'club-groups',
        'institute-members',
        'institute-functions',
      ];
      const currentRoute = this.$router.currentRoute.value.name;
      return groupRoutes.indexOf(currentRoute) > -1;
    },
    isHonorsActive() {
      const honorRoutes = [
        'club-honors',
      ];
      const currentRoute = this.$router.currentRoute.value.name;
      return honorRoutes.indexOf(currentRoute) > -1;
    },
    ...mapState(
      useLoginStore, {isLoggedIn: "isLoggedIn", isUser: "isUser", isClubAdmin: "isClubAdmin"},
    ),
    ...mapState(
      useMemberStore, {
        memberData: "getMemberData",
        isEmulatingMember: "isEmulatingMember",
      }
    ),
    ...mapState(
      useClubStore, {
        getClub: "getClubData"
      }
    )
  },
};
</script>

